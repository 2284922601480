<template>
  <div class="playbackPageWrap container">
          <header>
        <el-row class='hedaer-Bg'>
          <el-col :span="12">
            <div class="grid-content header-left-content">
                <div class='logo-wrap'><img src='../../assets/image/homeImg/logo.png'/></div>
                <div  class="app-title" @click="$router.push('/home')">河南省团干部网络教育平台</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          </el-col>
        </el-row>
      </header>
      <div class='main-content'>
        <el-row type="flex" class='contentWrap'>
          <el-col class="leftContent">
            <div class="grid-content bg-purple-light grid-content-left">
              <div class="leftSide"  @click="$router.back(-1)">
                <div class='backBtn'>&amp; &nbsp;返回</div>
              </div>
            </div>
          </el-col>
          <el-col class="RightContent">
            <div class="grid-content bg-purple-light">
              <div class="chapterTitle">{{chapterTitle}}</div>
                <div id="player"></div>
            </div>
          </el-col>
        </el-row>

      </div>
      <footer></footer>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      vodPlayerJs: 'https://g.alicdn.com/de/prismplayer/2.15.2/aliplayer-min.js',
      vid: '',
      playAuth: '',
      vodUrl: '',
      courseTitle: '',
      chapterTitle: '',
      studentChapterId: '',
      studentCourseId: '',
      timer: null,
      // vid: ''
      currentDuration: 0,
      totalDuration: 0,
      skinLayout: [{
        'name': 'bigPlayButton',
        'align': 'cc',
        'x': 30,
        'y': 80
      },
      {
        'name': 'errorDisplay',
        'align': 'tlabs',
        'x': 0,
        'y': 0
      },
      {
        'name': 'infoDisplay'
      },
      {
        'name': 'controlBar',
        'align': 'blabs',
        'x': 0,
        'y': 0,
        'children': [
          {name: 'playButton', align: 'tl', x: 15, y: 12}, // 播放开始暂停按钮
          {name: 'timeDisplay', align: 'tl', x: 10, y: 7}, // 播放视频时间
          {name: 'fullScreenButton', align: 'tr', x: 10, y: 12}, // 全屏
          {name: 'volume', align: 'tr', x: 5, y: 10} // 音量
        ]
      }],
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },
    loadPlayer () {
      console.log(this.skinLayout)
      let me = this
      let start = true
      let startProgress = 0
      let endProgress = 0
      const saveTime = function (memoryVideo, currentTime) {
        localStorage.setItem(memoryVideo, currentTime)
      }

      const getTime = (memoryVideo) => {
        console.log(this.currentDuration)
        // return返回的是自定义起播时间
        if (this.currentDuration>0 && this.currentDuration<this.totalDuration) {
          return this.currentDuration
        } else {
          return 0
        }
      }
      const Aliplayer = window.Aliplayer
      this.player = new Aliplayer({
        id: 'player',
        width: 1045,
        height: 620,
        vid: this.vid,
        playauth: this.playAuth,
        autoplay: true,
        showLine: 'false',
        preload: false,
        isLive: false,
        disableSeek: true,
        skinLayout: this.skinLayout,
        components: [{
          name: 'MemoryPlayComponent',
          type: window.AliPlayerComponent.MemoryPlayComponent,
          /* Set the first parameter to true to enable auto play. The default is false. */
          args: [true, getTime, saveTime]
        }]
      })
      // this.player.on('play', function () {
      //   if (this.currentDuration>0 && this.currentDuration<this.totalDuration) {
      //     this.player.seek(this.currentDuration)
      //   }
      // })
      // 暂停视频
      this.player.on('pause', function () {
      })
      // 开始拖拽startSeek
      this.player.on('startSeek', (e) => {
        if (start) {
          start = false
          startProgress = this.player.getCurrentTime()
        }
        // 拖拽开始，暂停视频
        this.player.pause()
      })
      // 完成拖拽completeSeek
      this.player.on('completeSeek', (e) => {
        start = true
        endProgress = this.player.getCurrentTime()
        console.log(endProgress, startProgress)
        if (endProgress < startProgress) {
          this.player.seek(endProgress)
        } else {
          this.player.seek(startProgress)
        }
        // 拖拽结束，播放视频
        this.player.play()
      })
      this.player.on('timeupdate', function (e) {
        // console.log(e)
        const currentTime = me.player.getCurrentTime()
        if (currentTime - 30>me.currentDuration) {
          me.addStudyTrack(currentTime)
          me.currentDuration = currentTime
        }
      })
      this.player.on('ended', function () {
      })
      if (this.currentDuration>0 && this.currentDuration<this.totalDuration) {
        this.player.seek(this.currentDuration)
        // this.player.on('loadedmetadata', (e) => {
        //   this.player.seek(this.currentDuration)
        // })
      }
    },
    // 上传进度
    addStudyTrack (currentTime) {
      const params = {
        studentChapterId: this.studentChapterId,
        studentCourseId: this.studentCourseId,
        studyDuration: currentTime
      }
      console.log(params)
      this.$axios.post(this.$apiUrl.addStudyTrack, params).then(res => {
        if (res && res.code === 200 && res.success) {
          console.log('res==', res)
          this.peopleNum = res.data
        }
      })
    },

    // 获取课程详情
    getCourseDetail () {
      console.log('mounted', this.$route.params.courseId)
      this.$axios.get(this.$apiUrl.loadChapterCourseDetail, {params: {id: this.$route.params.vodId}}).then(res => {
        console.log(res)
        let resData = res.data.data
        let {chapterList, courseTitle, courseId} = resData
        console.log(chapterList, courseTitle, courseId)
        this.courseTitle = courseTitle
        this.studentCourseId = courseId
        if (chapterList.length) {
          this.vid = chapterList[0].vodId
          this.chapterTitle = chapterList[0].chapterTitle
          this.getVideoPlayAuthFun()
          this.vodUrl = chapterList[0].vodUrl
          this.currentDuration = chapterList[0].studyDuration
          this.totalDuration = chapterList[0].totalDuration
          this.studentChapterId = chapterList[0].id
        }
      })
    },
    getVideoPlayAuthFun () {
      this.$axios.post(this.$apiUrl.getVideoPlayAuth, {videoId: this.vid}).then(res => {
        console.log(res)
        if (res.data.success) {
          this.playAuth = res.data.data.playAuth
          this.loadPlayerScript(this.loadPlayer)
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    console.log(this.$route.query, this.skinLayout)
    if (this.$route.query.pageFrom) {
    } else {
      this.skinLayout.forEach(item => {
        if (item.name == 'controlBar') {
          item.children.push({name: 'progress', align: 'blabs', x: 0, y: 44}) // 播放进度条
          item.children.push({name: 'setting', align: 'tr', x: 15, y: 12}) // 播放倍速、清晰度设置
          // 只取消倍速的播放
          // document.getElementsByClassName('prism-setting-speed')[0].style.display='none'
        }
      })
    }
    this.getCourseDetail()
    this.getUserInfo()
  },
  destroyed () {
    if (this.player) {
      this.player.destroy()
    }
  }
}
</script>

<style scoped lang="scss">
.playbackPageWrap{
    position: relative;
    min-height: 100vh;
      header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 42px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 42px;
    padding: 0 100px;

    .header-left-content {
      font-size: 20px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 33px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }

      ::v-deep .el-dropdown {
        font-size: 14px;
        color: #4774df;
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }
    .main-content{
        width: 1240px;
      margin: 0 auto 0;
      padding: 0px;
      min-height: 700px;
      padding-bottom: 50px;
      .contentWrap{
        justify-content: center
      }
      .leftContent{
        width: 94px;
      }
     .grid-content-left {
      display: flex;
      .leftSide {
        width: 74px;
        height: 699px;
        background: #4C5664;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        color: #ffff;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        .backBtn {
          width: 100%;
          background-color: #2F3A4A;
          height: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
      }
      .navAndSign {
        height: 699px;
        width: 149px;
        background: #ffffff;
        .returnIcon {
          padding: 24px 0px 30px 22px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          cursor: pointer;
        }
        .returnIcon:hover {
          color: #a4c2fa;
        }
        .title {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border-bottom: 1px solid rgba(112, 112, 112, 0.2);
          padding: 9px 18px;
        }
      }
      .signWrap {
        width: 249px;
        height: 593px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          padding: 30px 0px;
          color: "#000000";
          display: flex;
          flex-direction: column;
          font-size: 18px;
          cursor: pointer;
          text-align: center;
          > img {
            width: 50px;
            height: 50px;
          }
        }
        .nosignText {
          color: #1c4ecc;
        }
        .nosignoutText {
          color: #ff6f00;
        }
      }
    }
    .RightContent {
       width: 1045px;
       padding: 30px 0px;
      .chapterTitle {
        padding-left:19px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 14px;
      }
    }
    .courseCover {
      width: 1045px;
      height: 620px;
    }
        #player{
          width: 1045px;
          height: 620px;
        }
    }
    footer{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 50px;
        background: url('../../assets/image/homeImg/headerBg.png') no-repeat;
        background-size:100% 100%;
    }
}
</style>
